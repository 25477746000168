import { useRouter } from 'next/router';
import { type ReactElement } from 'react';
import { Tolgee, TolgeeProvider, useTolgeeSSR } from '@tolgee/react';
import { FormatIcu } from '@tolgee/format-icu';
import * as fr from '../i18n/translations.fr.json'

const tolgee = Tolgee()
    .use(FormatIcu())
    .init({
        defaultLanguage: 'fr',
        staticData: {
            fr: fr
        }
    });

interface LanguageProviderProps {
    children: ReactElement | ReactElement[]
}
const LanguageProvider = ({ children }: LanguageProviderProps) => {
    const router = useRouter();
    const ssrTolgee = useTolgeeSSR(tolgee, router.locale);

    return (
        <TolgeeProvider tolgee={ssrTolgee} options={{
            useSuspense: false
        }}>
            <>{children}</>
        </TolgeeProvider>
    );
};

export default LanguageProvider;
export {
    LanguageProvider
};
export type {
    LanguageProviderProps
};

