import RenderSnack, { type RenderSnackProps } from 'components/utils/renderSnack';
import { createContext, type ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import useTranslation from "hooks/useTranslation";


interface SnackbarContextProps {
    createSnack: (props: RenderSnackProps) => void,
    next: () => void,
    clear: () => void
}
const defaultSnackbarContext: SnackbarContextProps = {
    createSnack: (_props) => null,
    next: () => null,
    clear: () => null
};
const SnackbarContext = createContext<SnackbarContextProps>(defaultSnackbarContext);

let uniqueId = 2;

interface SnackbarProviderStateProps {
    current?: RenderSnackProps,
    queue: RenderSnackProps[]
}
interface SnackbarProviderProps {
    children?: ReactElement | ReactElement[]
}
const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
    const { t } = useTranslation();
    const [{ current, queue }, setState] = useState<SnackbarProviderStateProps>({ current: undefined, queue: [] });
    const queueRef = useRef(queue);
    queueRef.current = queue;

    const openNext = useCallback(() => {
        if (queueRef.current.length) {
            setState({ current: queueRef.current[0], queue: queueRef.current.slice(1) });
        } else {
            setState({ current: undefined, queue: [] });
        }   
    }, [queueRef]);
    
    const handleClose = useCallback(() => {
        setState((currentState: SnackbarProviderStateProps) => ({
            ...currentState,
            current: { ...currentState.current, open: false }
        }));
        const nextTrigger = setTimeout(openNext, 250);
        return () => clearTimeout(nextTrigger);
    }, [openNext, setState]);

    const handleClear = useCallback(() => {
        setState({ current: undefined, queue: []});
    }, [])

    const createSnack = useCallback(({ ...props }: RenderSnackProps) => {
        const id = uniqueId++;
        const snack = { ...props, id: id.toString(), open: true };

        if (!props.withoutSnackbar) {
            if (current) {
                setState({ current, queue: [...queueRef.current, snack] });
                handleClose();
            } else {
                setState({ queue: queueRef.current, current: snack });
            }
            return id;
        }
    }, [current, handleClose]); 

    const contextValue = useMemo(() => ({
        createSnack,
        next: handleClose,
        clear: handleClear
    }), [createSnack, handleClose, handleClear]);

    return (
        <>
            <SnackbarContext.Provider value={contextValue}>
                {current ? <RenderSnack key={current?.id} t={t} {...current} handleClose={handleClose} />: <></>}
                {children}
            </SnackbarContext.Provider>
        </>
    );
};


export default SnackbarProvider;
export {
    SnackbarProvider,
    SnackbarContext
};

