import { useTolgee, useTranslate } from '@tolgee/react';
import { useRouter } from 'next/router';

const useTranslation = (...args: any[]) => {
  return useTranslate(...args);
};

const useSelectedLanguage = () => {
  const tolgee = useTolgee(['language']);
  const router = useRouter();

  return {
    lang: tolgee.getLanguage(),
    setLang: (lang: string) => {
      console.warn(`setLang(${lang}) non authorisé`)
      //tolgee.changeLanguage(lang)
    },
    availableLangs: router.locales || []
  };
};

export default useTranslation;
export { useSelectedLanguage };