interface DataReturnType {
    [key: string | symbol | number]: undefined | boolean | string | number | DataReturnType | DataReturnType[]
}

interface ErrorsReturnType {
    [key: string]: string[]
}

interface LinkProps extends DataReturnType {
    url?: string;
    label: string;
    active: boolean;
}

interface PaginationReturnType<T> {
    current_page: number;
    data: T[];
    from: number;
    last_page: number;
    links: LinkProps[];
    per_page: number;
    to: number;
    total: number;
}

const defaultPagination: PaginationReturnType<any> = {
    current_page: 1,
    data: [],
    from: 0,
    last_page: 1,
    links: [],
    per_page: 12,
    to: 1,
    total: 0
};

interface PaginationUrlProps {
    url: string,
    page?: number,
    perPage?: number,
    search?: string,

    [key: string | symbol | number]: any
}

const paginateUrl = ({ url, ...props }: PaginationUrlProps) => {
    if (Object.values(props).length === 0) return url;
    const { body: _body, opt: _opt, ...rest } = props;
    return `${url}?${ [...Object.entries(rest).map(([k, v]) => v ? [k, v].join('=') : null)].filter(obj => !!obj).join('&')}`;
};


const SHARED_API_URLS: { [key: string | symbol]: string } = {
    ACCOUNT: 'me',
    ACCOUNT_PASSWORD: 'me/password',
    ACCOUNT_LANGUAGE: 'me/language',
    AUTH_RESET_ASK: 'auth/password/email',
    AUTH_RESET_PASSWORD: 'auth/password/reset',
    LANGUAGES: 'languages',
    AVATARS: 'avatars',
    MAP: 'map',
    LOCALIZATION: 'geo/localization',
    ESTABLISHMENT_SHARED: 'establishments'
};


export {
    SHARED_API_URLS,
    paginateUrl,
    defaultPagination
};
export type {
    DataReturnType,
    ErrorsReturnType,
    LinkProps,
    PaginationUrlProps,
    PaginationReturnType
};

