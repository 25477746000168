import useMutateSWRPartialKey from "hooks/useMutateSWRPartialKey";
import { createContext, useCallback, useEffect, useMemo, useRef, type ReactElement } from 'react';
import { API_URLS } from "services/api";
import { fetchWrapper, type Cancellable, type Redirectable } from "services/fetchWrapper";
import { type FetchProps } from "services/types";
import { useLoadingProgress } from "shared/hooks/loadingProgress";
import useSWR, { type KeyedMutator } from "swr";
import { useLocalStorage } from "usehooks-ts";
import { type EstablishmentProps } from "./EstablishmentContext";


interface VerboseProps {
    verbose?: boolean,
    hideLoadingScreen?: boolean
}
interface RightsProps {
    [key: string]: { [key2: string]: boolean }
}
interface UserTokenProps {
    id: number,
    profile_picture: string,
    language: string
}
interface FvToken {
    access_token: string,
    refresh_token: string,
    received_at: number,
    expires_in: number
}
interface LoginProps extends Redirectable, Cancellable, VerboseProps {
    username: string,
    password: string,
}
interface RefreshProps extends Redirectable, Cancellable, VerboseProps {
    test?: boolean
}
interface AskResetPasswordProps extends Redirectable, Cancellable, VerboseProps {
    email: string
}
interface ResetPasswordProps extends Redirectable, Cancellable, VerboseProps {
    email: string,
    password: string,
    password_confirmation: string,
    token: string
}
interface LogoutProps extends Redirectable, Cancellable, VerboseProps {}

interface AuthContextProps {
    authenticated: boolean,
    getApi: (_: FetchProps) => Promise<any>,
    postApi: (_: FetchProps) => Promise<any>,
    deleteApi: (_: FetchProps) => Promise<any>,
    fetchWithToken: (props: FetchProps, _fetcher: (_: FetchProps) => Promise<any>) => Promise<any>,
    login: (props: LoginProps) => Promise<unknown>,
    logout: (props?: LogoutProps) => Promise<unknown>,
    refresh: KeyedMutator<LoginResponse>,
    loading: boolean,
    right: RightsProps,
    establishments: EstablishmentProps[],
    user: UserTokenProps,
    askResetPassword: (props: AskResetPasswordProps) => Promise<unknown>,
    resetPassword: (props: ResetPasswordProps) => Promise<unknown>,
    postApiUnauth: (_: FetchProps) => Promise<any>,
}
const defaultAuthContext: AuthContextProps = {
    authenticated: false,
    login: Promise.reject,
    logout: Promise.reject,
    loading: true,
    askResetPassword: Promise.reject,
    resetPassword: Promise.reject,
    getApi: Promise.reject,
    postApi: Promise.reject,
    deleteApi: Promise.reject,
    fetchWithToken: Promise.reject,
    refresh: Promise.reject,
    right: {},
    establishments: [],
    user: { id: 0, profile_picture: '', language: '' },
    postApiUnauth: Promise.reject,
};
const AuthContext = createContext<AuthContextProps>(defaultAuthContext);


interface OtherProps {
    establishments: EstablishmentProps[],
    right: RightsProps,
    user: UserTokenProps
}
type LoginResponse = FvToken & OtherProps;
const defaultLoginResponse: LoginResponse = {
    access_token: "",
    establishments: [],
    expires_in: 0,
    received_at: 0,
    refresh_token: "",
    right: {},
    user: { id: 0, profile_picture: '', language: '' }
};
interface AuthProviderProps {
    loginUrl?: string,
    logoutUrl?: string,
    askResetUrl?: string,
    resetUrl?: string,
    postApiFetcher?: (_: FetchProps) => Promise<LoginResponse>,
    children: ReactElement
}

const AuthProvider = ({
                          loginUrl = API_URLS.AUTH,
                          logoutUrl = API_URLS.LOGOUT,
                          askResetUrl = API_URLS.AUTH_ASK_RESET,
                          resetUrl = API_URLS.AUTH_RESET_PASSWORD,
                          postApiFetcher = fetchWrapper.postApi,
                          children
                      }: AuthProviderProps) => {
    const { clearCache } = useMutateSWRPartialKey();

    const authPayloadFormatter = useCallback(({ url, body, opt }: FetchProps) => {
        return ({
            url,
            body: {
                ...body,
                client_id: process.env.NEXT_PUBLIC_AUTH_CLIENT_ID,
                client_secret: process.env.NEXT_PUBLIC_AUTH_CLIENT_SECRET
            },
            opt: {
                ...opt,
                credentials: 'include'
            }
        })
    }, []);

    const refreshToken = useRef<string | null>(null);
    const authFetcher = useCallback(({ body, ...args }: FetchProps) => {
        return postApiFetcher(authPayloadFormatter({
            ...args,
            body: {
                ...(body ? body : ({
                    refresh_token: refreshToken.current,
                    grant_type: 'refresh_token'
                })),
            }
        })).then(res => {
            refreshToken.current = res.refresh_token;
            return ({ ...res, received_at: Date.now() })
        })
    },[authPayloadFormatter, postApiFetcher])

    const refreshInterval = useCallback(({ received_at = Date.now(), expires_in = -1 }: Partial<FvToken> = {}) => {
        return Math.max(0, (received_at + expires_in * 1000) - Date.now());
    }, []);

    const handleError = useCallback((err: any) => {
        refreshToken.current = null;
    }, [])

    const { data: authData, isValidating, error, mutate } = useSWR<LoginResponse>({
        url: loginUrl
    }, authFetcher, {
        fallbackData: defaultLoginResponse,
        revalidateOnMount: true,
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        shouldRetryOnError: false,
        keepPreviousData: true,
        dedupingInterval: 0,
        refreshInterval: latestData => refreshInterval(latestData),
        refreshWhenOffline: true,
        onError: err => handleError(err)
    });

    const { start, done } = useLoadingProgress();
    useEffect(() => { return isValidating ? start() : done();}, [start, done, isValidating]);

    const isAccessTokenValid = useCallback((token?: Partial<FvToken>) => {
        if(!token?.received_at || !token?.expires_in){ return false; }
        return refreshInterval(token) > 0
    }, [refreshInterval]);

    /**
     * fonction en charge de l'ajout des headers d'authentification
     * TODO: ajouter les allowed_origins
     */
    const authHeaders = useCallback(async () => {
        if (authData?.access_token && isAccessTokenValid(authData)) {
            return Promise.resolve({ headers: { Authorization: `Bearer ${authData.access_token}` } });
        } else {
            return Promise.reject({ message: 'Token not valid' });
        }
    }, [authData]);

    const fetchWithToken = useCallback(async (props: FetchProps, _fetcher: (_: FetchProps) => Promise<any>) => {
        return authHeaders()
            .then(({ headers }) => _fetcher({
                ...props,
                opt: {
                    ...props.opt,
                    headers: {
                        ...props.opt?.headers,
                        ...headers
                    }
                }
            }));
    }, [authHeaders]);

    const getApi = useCallback((props: FetchProps) => fetchWithToken(props, fetchWrapper.getApi), [fetchWithToken]);
    const postApi = useCallback((props: FetchProps) => fetchWithToken(props, fetchWrapper.postApi), [fetchWithToken]);
    const deleteApi = useCallback((props: FetchProps) => fetchWithToken(props, fetchWrapper.deleteApi), [fetchWithToken]);

    //! ne pas mettre setAuthEvent en dependance des callback -> boucle infinie
    const [authEvent, setAuthEvent] = useLocalStorage('auth-event', '');
    const triggerAuthEvent = useCallback((type: 'login' | 'logout') => {
        return Promise.resolve()
            .then(() => setAuthEvent(`${type}.${performance.now()}`))
            .then(() => setAuthEvent(``))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const login = useCallback(({ controller, redirectUri = '/', ...args }: LoginProps) => {
        return mutate(authFetcher({ url: loginUrl, body: { ...args, grant_type: 'password' }, opt: { signal: controller?.signal } }), { revalidate: false })
            .then(() => triggerAuthEvent('login'))
    }, [mutate, authFetcher, loginUrl, triggerAuthEvent]);

    const logout = useCallback(({ controller, redirectUri = '/login', ...args }: LogoutProps = {}) => {
        const refreshLocalData = () => {
            refreshToken.current = null;
            return mutate(defaultLoginResponse, { revalidate: true })
        };
        const refreshDistantData = () => getApi(authPayloadFormatter({
            url: logoutUrl,
            body: { ...args, grant_type: 'password' },
            opt: { signal: controller?.signal }
        }));
        return refreshDistantData()
            .then(() => triggerAuthEvent('logout'))
            .finally(() => {
                return Promise.all([clearCache(), refreshLocalData()]);
            })
    }, [getApi, authPayloadFormatter, logoutUrl, mutate, triggerAuthEvent])


    useEffect(() => {
        if (![undefined, null, ''].includes(authEvent)) {
            switch (authEvent.split('.')[0]) {
                case 'login': {
                    mutate();
                    break;
                }
                case 'logout': {
                    if(isAccessTokenValid(authData)) {
                        logout();
                    }
                    break;
                }
                default: break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authEvent]);

    const askResetPassword = useCallback(({ controller, email }: AskResetPasswordProps) => {
        const body = {
            email
        }
        return postApiFetcher(authPayloadFormatter({ url: askResetUrl, body, opt: { signal: controller?.signal } }))
    }, [postApiFetcher, authPayloadFormatter, askResetUrl]);

    const resetPassword = useCallback(({ controller, email, password, password_confirmation, token }: ResetPasswordProps) => {
        const body = {
            email,
            password,
            password_confirmation,
            token
        }
        return postApiFetcher(authPayloadFormatter({ url: resetUrl, body, opt: { signal: controller?.signal } }))
    }, [resetUrl, postApiFetcher, authPayloadFormatter]);

    const contextMethods = useMemo(() => ({
        getApi,
        postApi,
        deleteApi,
        fetchWithToken,
        login,
        logout,
        askResetPassword,
        resetPassword,
        refresh: mutate,
        postApiUnauth : fetchWrapper.postApi
    }), [getApi, postApi, deleteApi, fetchWithToken, login, logout, askResetPassword, resetPassword, mutate, fetchWrapper.postApi]);

    const contextValue: AuthContextProps = useMemo(() => ({
        ...contextMethods,
        loading: isValidating,
        get authenticated(){ return isAccessTokenValid(authData); },
        get user(){ return authData?.user || defaultLoginResponse.user; },
        get establishments(){ return authData?.establishments || defaultLoginResponse.establishments; },
        get right(){ return authData?.right || defaultLoginResponse.right; }
    }), [authData, isValidating, isAccessTokenValid, contextMethods]);

    return (
        <>
            <AuthContext.Provider value={contextValue}>
                {children}
            </AuthContext.Provider>
        </>
    );
}

export default AuthProvider;
export {
    AuthContext
};
export type {
    AuthContextProps,
    AuthProviderProps,

    FvToken,
    RightsProps,
    LoginProps,
    LogoutProps,
    AskResetPasswordProps,
    ResetPasswordProps,
    RefreshProps,
    LoginResponse

};

