import CloseIcon from '@mui/icons-material/Close';
import Alert, { type AlertProps } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import Slide, { type SlideProps } from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import { type ForwardedRef, forwardRef, type ReactElement, useCallback, useId } from 'react';


const Transition = ({ ...props }: SlideProps) => (
    <Slide {...props} direction='left' />
);

interface RenderSnackProps extends AlertProps {
    id?: string,
    translateKey?: string,
    message?: string,
    originalMessage?: string,
    open?: boolean,
    handleClose?: () => void
    withoutSnackbar?: boolean,
    statusCode?: number,
    t?: (key: string, options?: any | undefined) => string
}
const RenderSnack = ({ id, open, handleClose, translateKey, t: initialT, message, originalMessage, title, severity, statusCode, withoutSnackbar, ...props }: RenderSnackProps, ref: ForwardedRef<HTMLDivElement>) => {
    const messageId = useId();
    const t = useCallback((str: string, opt?: any) => {
        if (initialT) {
            if (translateKey) {
                str = translateKey + '.' + str;
            }
            return (initialT(str));
        } else {
            return str;
        }
    }
    , [initialT, translateKey]);

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            ContentProps={{
                ['aria-describedby']: messageId
            }}
            onClose={handleClose}
            autoHideDuration={(severity === 'error' || severity === 'warning') ? null : 6000}
            open={open && !withoutSnackbar}
            TransitionComponent={Transition}
            ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
            //sx={{ position: 'absolute', top: 0 }}
            sx={{ mt: 0 }} //TODO: comportement souhaité similaire à position: 'sticky' pour la caller en dessous du menu
            ref={ref}
        >
            <Alert
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={handleClose}
                        size={'small'}
                        sx={{ alignItems: 'center', justifyContent: 'center' }}
                    >
                        <CloseIcon fontSize={'small'} />
                    </IconButton>
                ]}
                severity={severity}
                variant={'filled'}
                sx={{ alignItems: 'center', justifyContent: 'center' }}
                {...props}
            >
                {title && <AlertTitle>{t(title)}</AlertTitle>}
                {t(message || '')}
            </Alert>
        </Snackbar>
    );
};

const RenderSnackWithRef = forwardRef(RenderSnack) as
    (p: RenderSnackProps & { ref?: ForwardedRef<HTMLDivElement> }) => ReactElement;


export default RenderSnackWithRef;
export {
    RenderSnackWithRef as RenderSnack
};
export type {
    RenderSnackProps
};

