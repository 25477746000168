import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import { createContext, type ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';


interface Progress {
    value: number
    start: () => void | (() => void)
    done: () => void | (() => void)
}

// 1. Creating a context
const LoadingProgressContext = createContext<Progress>({
    value: 0,
    start: () => {},
    done: () => {}
});

// 2. useLoadingProgress hook
const useLoadingProgress = (): Progress => {
    return useContext<Progress>(LoadingProgressContext);
};

// 3. LoadingProgress component
const LoadingProgress = () => {
    const { value } = useLoadingProgress();

    return (
        <Stack
            direction={'row'}
            sx={{ align: 'flex-end', position: 'absolute', top: 0, left: 0, right: 0 }}
        >
            <LinearProgress value={value} sx={{ height: 2, width: '100vw', zIndex: 9999999 }} color={'secondary'}/>
        </Stack>
    );
};

interface LoadingProgressProviderProps {
    children: ReactElement | ReactElement[]
}

// 4. LoadingProgressProvider
const LoadingProgressProvider = ({ children }: LoadingProgressProviderProps): ReactElement => {
    // 5. Variables
    //const step = useRef(5)
    const step = useRef(100);
    const [value, setValue] = useState(0);
    const [isOn, setOn] = useState(false);

    // 6. useEffect
    useEffect(() => {
        if (isOn) {
            let timeout: null | ReturnType<typeof setTimeout>;
            step.current = 100 - value;

            if (value <= 98) {
                timeout = setTimeout(() => {
                    setValue(value + step.current);
                }, 100);
            }

            return () => {
                if (timeout) {
                    clearTimeout(timeout);
                }
            };
        }
    }, [value, isOn]);

    // 8. done
    const done = useCallback(() => {
        setValue(100);
        const timeout = setTimeout(() => {
            setOn(false);
        }, 200);
        return () => clearTimeout(timeout);
    }, []);

    // 7. start
    const start = useCallback(() => {
        setValue(0);
        setOn(true);
        return () => {
            done();
        };
    }, [done]);

    const contextValue = useMemo(() => ({
        value,
        start,
        done
    }), [value, start, done]);

    return (
        <LoadingProgressContext.Provider
            value={contextValue}
        >
            {isOn ? <LoadingProgress/> : <></>}
            {children}
        </LoadingProgressContext.Provider>
    );
};


export default useLoadingProgress;
export {
    LoadingProgressProvider,
    LoadingProgressContext,
    useLoadingProgress
};
export type {
    LoadingProgressProviderProps
};

