import { useCallback } from 'react';
import { useSWRConfig } from 'swr';

const useMutateSWRPartialKey = () => {
    const { cache, mutate } = useSWRConfig();
    const partialMutate = useCallback(
        (matcher: RegExp, ...args: any) => {
            if (!(cache instanceof Map)) {
                throw new Error(
                    'matchMutate requires the cache provider to be a Map instance'
                );
            }

            const keys = [];

            for (const key of cache.keys() as unknown as string[]) {
                if (matcher.test(key)) {
                    keys.push(key);
                }
            }

            const mutations = keys.map((key) => mutate(key, ...args));
            return Promise.all(mutations);
        },
        [cache, mutate]
    );

    const clearCache = useCallback(() => {
        if (!(cache instanceof Map)) {
            throw new Error(
                'clearCache requires the cache provider to be a Map instance'
            );
        }

        for (const key of cache.keys() as unknown as string[]) {
            if (key.includes('authenticated')) {
                cache.delete(key);
            }
        }
        if (window?.localStorage) {
            window.localStorage.clear();
        }
    }, [cache]);

    return { partialMutate, clearCache };
};

export default useMutateSWRPartialKey;
export { useMutateSWRPartialKey };

