import { alpha, createTheme, type Theme } from '@mui/material/styles';
import commonTheme from 'shared/utils/theme';

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        body3: true
    }
}

// Create a theme instance.
//https://javascript.plainenglish.io/extend-material-ui-theme-in-typescript-a462e207131f
const FvTheme: Theme = createTheme(commonTheme, {
    palette: {
        // TODO: check peut-être pour ne les avoir que dans le front, pas utiles en back mais j'ai rajouté pour que ça build
        childPrimary: {
            main: '#112D43',
            contrastText: '#FFFFFF'
        },
        childSecondary: {
            main: '#FFAD0F',
            contrastText: '#0A1A27'
        },
        childTertiary: {
            main: "#AF3924",
            contrastText: "#FFFFFF"
        },
        primary: {
            main: '#112D43',
            900: '#050C12',
            800: '#08131C',
            700: '#0B1A27',
            600: '#0D2233',
            500: '#112D43',
            400: '#163953',
            300: '#125281',
            200: '#64A9DD',
            100: '#C9E8FF',
            50: '#F5FBFF',
            contrastText: '#FFFFFF'            
        },
        secondary: {
            main: '#FFAD0F',
            900: '#BF810B',
            800: '#E2980C',
            700: '#FFAD0F',
            600: '#FFBB39',
            500: '#FFC75D',
            400: '#FDCF78',
            300: '#FFDA93',
            200: '#FCE0A9',
            100: '#FAE7C2',
            50: '#FFF7E8',
            contrastText: '#0A1A27' 
        },
        tertiary: { 
            main: "#AF3924",
            contrastText: '#FFFFFF'
        },
        background: {
            paper: '#FFF',
            default: 'hsla(0,0%,100%, 0.95)'
        },
        error: {
            main: '#DD0000'
        },
        success: {
            main: '#56C798'
        },
        neutral: {
            main: '#fff',
            contrastText: '#112D43',
            light: 'hsla(0,0%,100%, 0.99)'
        },
        weloginPrimary: {
            main: '#44A0C2'
        },
        disabled: {
            main: '#E0E0E0',
            dark: '#C0C0C0',
            light: '#F0F0F0'
        }
    },
    typography: {
        fontSize: 16,
        h1: {
            fontSize: '2rem',
            ['@media screen and (min-width: 900px)']: {
                fontSize: '3.3rem'
            },
            fontWeight: '300',
            lineHeight: 1,
            letterSpacing: '0.01em',
            color: '#112D43'
        },
        h2: {
            fontSize: '1.5rem',
            marginTop: '1.5rem',
            marginBottom: '1.5rem'
        },
        h3: {
            fontSize: '1.25rem',
            marginTop: '1.5rem',
            marginBottom: '1.5rem'
        },
        body1: {
        },
        body2: {
            fontSize: '0.875rem',
            color: '#000000',
            lineHeight: 1.1
        },
        body3: {
            fontSize: '0.875rem',
            color: '#000000',
            lineHeight: 1.1
        },
    },
    components: {
        MuiAlert: {
            styleOverrides: {
                filledSuccess: ({ theme }: { theme:Theme }) => ({
                    color: '#fff'
                })
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    boxShadow: 'none',
                    lineHeight: 1.1,
                    ['&:hover, &:focus']: {
                        boxShadow: 'none'
                    }
                },
                sizeSmall: ({ theme }: { theme:Theme }) => ({
                    fontSize: '13px'
                }),
                sizeMedium: ({ theme }: { theme:Theme }) => ({
                    minHeight: 28
                }),
                sizeLarge: ({ theme }: { theme:Theme }) => ({
                    minHeight: 38
                }),
                containedPrimary: ({ theme }: { theme:Theme }) => ({
                    ['&:hover, &:focus']: {
                        backgroundColor: theme.palette.primary['300'],
                        color: '#fff'
                    }
                }),
                containedSecondary: ({ theme }: { theme:Theme }) => ({
                    ['&:hover, &:focus']: {
                        backgroundColor: theme.palette.secondary['600']
                    }
                }),
                outlinedSecondary: ({ theme }: { theme:Theme }) => ({
                    borderColor: theme.palette.secondary['900'],
                    color: theme.palette.secondary['900'],
                    ['&:hover, &:focus']: {
                        borderColor: theme.palette.secondary['900'],
                        color: theme.palette.secondary['900'],
                        backgroundColor: alpha(theme.palette.secondary.main, 0.1)
                    }
                }),
                containedNeutral: ({ theme }: { theme:Theme }) => ({
                    color: theme.palette.primary.main,
                    ['&:hover, &:focus']: {
                        backgroundColor: theme.palette.primary['100']
                    }
                }),
                containedSuccess: ({ theme }: { theme:Theme }) => ({
                    color: theme.palette.neutral.main
                })
            }
        },
        MuiChip: {
            styleOverrides: {
                colorPrimary: ({ theme }: { theme:Theme }) => ({
                    ['&:hover']: {
                        backgroundColor: theme.palette.primary['300'],
                        color: '#fff'
                    }
                }),
                colorSecondary: ({ theme }: { theme:Theme }) => ({
                    ['&:hover']: {
                        backgroundColor: theme.palette.secondary['600']
                    }
                })
            }
        },
        MuiFab: {
            styleOverrides: {
                sizeSmall: ({ theme }: { theme:Theme }) => ({
                    fontSize: '13px'
                }),
                sizeMedium: ({ theme }: { theme:Theme }) => ({
                    fontSize: '14px'
                }),
                primary: ({ theme }: { theme:Theme }) => ({
                    ['&:hover']: {
                        backgroundColor: theme.palette.primary['300'],
                        color: '#fff'
                    }
                }),
                secondary: ({ theme }: { theme:Theme }) => ({
                    ['&:hover']: {
                        backgroundColor: theme.palette.secondary['600']
                    }
                })
            }
        }
    }
});

export default FvTheme;
export { FvTheme };

