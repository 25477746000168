import { type EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import AuthProvider from 'contexts/AuthContext';
import LanguageProvider from 'contexts/LanguageContext';
import SnackbarProvider from 'contexts/SnackbarContext';
import { LoadingProgressProvider } from 'hooks/loadingProgress';
import { type AuthPageProtectProps } from 'hooks/usePageProtect';
import { type NextComponentType, type NextPage, type NextPageContext } from 'next';
import { type AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { type ReactElement } from 'react';
import { API_URLS } from 'services/api';
import { SWRConfig } from 'swr';
import { createEmotionCache } from '../utils/createEmotionCache';
import FvTheme from '../utils/theme';

interface LayoutProps {
    children: ReactElement | ReactElement[],
    pageProtectProps? : AuthPageProtectProps
}

const DefaultLayout = dynamic(() => import('components/layout/Layout'), {
    ssr: false
});
const PreventDataLossContext = dynamic(() => import('shared/contexts/PreventDataLossContext'), {
    ssr: false
});



// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

type NextPageWithAuth = NextPage & {
    loading: ReactElement,
    Layout: typeof DefaultLayout
    // Layout?: (data: LayoutProps) => JSX.Element //NextComponentType<NextPageContext>,
    LayoutProps?: LayoutProps
};
type NextComponentWithAuth = NextComponentType<NextPageContext> & Partial<NextPageWithAuth>;
interface FvAppProps extends AppProps {
    emotionCache?: EmotionCache,
    fallback?: object
    Component : NextComponentWithAuth
}


export default function FvApp({ 
    Component,
    emotionCache = clientSideEmotionCache, 
    fallback, 
    pageProps
}: FvAppProps) {
    const Layout = Component?.Layout || DefaultLayout;

    return (
        <LanguageProvider>
            <CacheProvider value={emotionCache}>
                <Head>
                    <title>{'Pro French Vadrouilles'}</title>
                    <meta name={'viewport'} content={'initial-scale=1, width=device-width'} />
                    <meta name={'description'} content={'FrenchVadrouilles'}/>
                    <link rel={'icon'} type={'image/svg'} href={'/fv_logo_original_twotone_24dp.svg'}/>
                </Head>
                <SWRConfig value={{ fallback }}>
                    <ThemeProvider theme={FvTheme}>
                        <CssBaseline />
                        <LoadingProgressProvider>
                            <SnackbarProvider>
                                <AuthProvider
                                    loginUrl={API_URLS.AUTH}
                                    askResetUrl={API_URLS.AUTH_RESET_ASK}
                                    resetUrl={API_URLS.AUTH_RESET_PASSWORD}
                                >
                                    <PreventDataLossContext>
                                        <Layout {...Component?.LayoutProps}>
                                            <Component {...pageProps} />
                                        </Layout>
                                    </PreventDataLossContext>
                                </AuthProvider>
                            </SnackbarProvider>
                        </LoadingProgressProvider>
                    </ThemeProvider>
                </SWRConfig>
            </CacheProvider>
        </LanguageProvider>
    );
}

export type {
    LayoutProps
};

