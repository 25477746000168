import { fetchWrapper } from 'services/fetchWrapper';
import {
    SHARED_API_URLS,
    paginateUrl,
    type DataReturnType,
    type ErrorsReturnType,
    type LinkProps,
    type PaginationReturnType,
    type PaginationUrlProps
} from 'shared/services/api_base';


const API_BASE_URL = `${process.env.NEXT_PUBLIC_REACT_APP_BACKEND}/api`;

const SHORT_API_URLS: { [key: string | symbol]: string } = {
    ...SHARED_API_URLS,
    AUTH: 'pro/auth/login',
    LOGOUT: 'pro/auth/logout',
    ESTABLISHMENT_INDEX: 'pro/me/establishments',
    VADROUILLEURS: 'pro/vadrouilleurs',
    ESTABLISHMENTS: 'pro/establishments',
    STRUCTURES: 'pro/structures',
    CITIES: 'pro/cities',
    USERS_PRO: 'pro/pro',
    USERS: 'pro/users',
    USERS_ESTABLISHMENT: 'pro/establishments',
    POIS: 'pro/pois',
    POITYPES: 'pro/poi-types',
    POI_SIT: 'pro/datatourisme',
    THEMES: 'pro/themes',
    SUBTHEMES: 'pro/themes/sub-themes',
    TAGS: 'pro/tags',
    FRENCHVADROUILLESTAGS: 'pro/frenchvadrouilles-tags',
    IMAGES: 'pro/images',
    VADROUILLES: 'pro/vadrouilles',
    WELOGIN: 'pro/welogin',
    VADROUILLE_DELETION_REQUESTS: 'pro/vadrouille-deletion-requests',
    MARKETPLACE_REGISTRATIONS: 'pro/marketplace-registrations',
    CONTACT: 'pro/contact'
};

const API_URLS: { [key: keyof typeof SHORT_API_URLS]: string } = new Proxy(SHORT_API_URLS, {
    get : (obj, prop) => `${API_BASE_URL}/${obj[prop]}`    
});

interface ServiceProps<T extends DataReturnType> {
    url: string,
    fetcher: typeof fetchWrapper,
    data?: T,
    options?: DataReturnType
}

const mockNetworkCall = ({ ...props }): Promise<DataReturnType> => {
    return new Promise((resolve) => resolve({}));
}; 

export {
    paginateUrl,
    mockNetworkCall,
    API_URLS,
    API_BASE_URL
};
export type {
    ServiceProps,
    DataReturnType,
    LinkProps,
    PaginationReturnType,
    ErrorsReturnType,
    PaginationUrlProps
};

