//@ts-nocheck
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
    interface PaletteOptions {
        tertiary: PaletteColorOptions;
        neutral: PaletteColorOptions;
        disabled: PaletteColorOptions;
        weloginPrimary: PaletteColorOptions;
        background: PaletteColorOptions;
        childPrimary: PaletteColorOptions;
        childSecondary: PaletteColorOptions;
        childTertiary: PaletteColorOptions;
    }
    interface Palette {
        tertiary: Palette['primary'];
        childPrimary: Palette['primary'];
        childSecondary: Palette['primary'];
        childTertiary: Palette['primary'];
        neutral: Palette['primary'];
        disabled: Palette['primary'];
        weloginPrimary: Palette['primary'];
        background: Palette['primary']
    }
    interface PaletteColor {
        main: string,
        [key: number | string]: string
    }
}

declare module '@mui/material/Avatar' {
    interface ButtonBasePropsColorOverrides {
        neutral: true;
        tertiary: true;
        disabled: true,
        background: true
    }
}

declare module '@mui/material/ButtonBase' {
    interface ButtonBasePropsColorOverrides {
        neutral: true;
        tertiary: true;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        neutral: true;
        tertiary: true;
        childPrimary: true;
        childSecondary: true;
        childTertiary: true;
    }
}

declare module '@mui/material/ButtonGroup' {
    interface ButtonGroupPropsColorOverrides {
        neutral: true;
        tertiary: true;
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        neutral: true;
        disabled: true;
        tertiary: true;
        childPrimary: true;
        childSecondary: true;
        childTertiary: true;
    }
}

declare module '@mui/material/SvgIcon' {
    interface SvgIconPropsColorOverrides {
        neutral: true;
        disabled: true;
        tertiary: true;
        childPrimary: true;
        childSecondary: true;
        childTertiary: true;
    }
}

declare module '@mui/material/Chip' {
    interface ChipPropsColorOverrides {
        neutral: true;
        disabled: true;
        tertiary: true;
        childPrimary: true;
        childSecondary: true;
        childTertiary: true;
    }
}

declare module '@mui/material/Badge' {
    interface BadgePropsColorOverrides {
        tertiary: true;
    }
}

declare module '@mui/material/Tabs' {
    interface TabsPropsColorOverrides {
        neutral: true
    }

    interface TabsPropsTextColorOverrides {
        neutral: true
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        body3: true
    }
}

const commonTheme = createTheme({
    direction: 'ltr'
});

export default commonTheme;